
import React from 'react';

const Requirements = (props) => {
  return (
    <>
      <div id="text_container"> <p> There are no members states to determine any requirements </p> </div>
    </>
  );
}

export default Requirements;
