
import React from 'react';

const Projects = (props) => {
  return (
    <>
      <div id="text_container">
        <p>
          In the best-case scenario that all 54 nation states give their permission and consent to work with us. There will be a number of projects that will not be specifically targeted at individual nations but will instead be structured as Intra-continental projects.
          <br />
        </p>

        <ul>
          The primary function of this organisation will be establish a secure and efficient system for the collection of taxes and other government income.
          <br />
          This will remove the possibility of funds being diverted from their intended purpose since coporations and individuals will be paying their taxes direclty to the government with no use of middle men.
          <br />
          <li></li>
          Secondary Projects

          <li>
            (I)	The construction of an Intracontinental road and railway system to make travelling from one country to another faster and easier.
          </li>

          <li>
            (II)	The improvement and construction of our utilities delivery systems, ranging from piped water to electricity.
          </li>

          <li>
            (III) The construction of and improvement of medical centers to make medical care more accessible to the people who might need it and reduce morbidly for curable illnesses.
          </li>

          <li>
            (IV) The improvement and restructuring of the education system where necessary to better prepare students.
          </li>

        </ul>

      </div>
    </>
  );
}

export default Projects;
