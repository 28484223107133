
import React from 'react';

const Footer = () =>
 {
  return (
    <div id ="footer_bar">
    </div>
  )
 }

export default Footer
