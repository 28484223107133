
import React from 'react';

const Home = (props) => {
  return (
    <>
      <div id="text_container">
        <p>
          The Current borders of most of the 54 of the Nation States on the African Continent as they stand today are not a product of wars between nation states, they are not a consequence of tribal struggles or allegiances,  they were not even conceived of nor were they drawn up or negotiated by any African alive or dead.
        </p>

        <p>
          Instead, the boundaries that have come to define Africans -in most cases being the principal characteristic of most Africans’ identities- and also denote the borders between the African nation states as they are today, are largely a consequence of European colonial rule.
        </p>

        <p>
          The initial formal portioning of the African Continent was agreed upon in a European delegation in Berlin in a meeting that lasted only three months from November of 1884 to February of 1885, this gathering was called and organized by the chancellor of Germany at the time, Otto Von Bismarck.
        </p>

        <p>
          Although the Berlin conference cannot be said to be the single precipitating factor for the European colonization of the African Continent because long before the meeting was ever called, a few of the European powers had already established a permanent presence on many of the African coasts.
          <br />
          The Berlin conference was, however, a definite catalyst for the Europeans’ conquest for African land.
          <br />
          But Even more importantly, it had two marked consequences
        </p>
        <ul>
          <li>
            (I) It lent credence and legitimacy to the European occupiers, as was in the case of king Leopold of Belgium annexing the Congo basin as “his own personal property”.
          </li>
          <li>
            (II) It parceled out African land to other European monarchs and opened up the interior of the continent that had hither to been largely an unknown to most foreigners.
          </li>
        </ul>

        <p>
          It’s not easy to fix precise beginning and end dates for European colonial rule in Africa, and although the Berlin conference can be used a convenient clutch to mark the beginning, the end came in a more slowly and protracted manner. Libya was among the first to achieve independence from the Europeans in 1951 and Zimbabwe among the last in 1980 almost 30 years later.
        </p>

        <p>
          The departure of the Europeans and the preceding occupation and partition before, have had lasting consequences on the continent, many of them for the worst.
        </p>

        <p>
          I mention all this not to lay blame nor is it meant as a way to displace responsibility, it’s intended to give a summarized history as to how the face of the continent has arrived at its current state. It is also meant to reinforce the idea that no matter our different national identities, we do share a common identity as Africans
        </p>

        <p>
          Any observer would not fail to notice that the same or similar sets of problems have plagued most of if not all our countries ever since we achieved independence, and yet each individual nation state has strived to meet these challenges mostly as an individual unit with very minimal meaningful bilateralism.
        </p>

        <p>
          But it is also easy to notice that there is and always has been room and the desire for a greater co-operation.
        </p>

      </div>
    </>
  );
}

export default Home;
