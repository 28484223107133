
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Requirements from './Requirements';
import Members from './Members';
import Home from './Home';
import Proposal from './Proposal';
import Projects from './Projects';
// import dark_logo from '../StyleSheets/logo/logo_dark.png';
import logo from '../StyleSheets/logo/logo.png';

const NavigationHandler = (props) => {
  return (
    <div id="root_container">
      <div id="logo_container"> <img id="logo_dark" src={logo} alt="cafti logo" /> </div>
      <Switch>
        <Route exact path="/Requirements" render={(props) => <Requirements {...props} />} />
        <Route exact path="/Members" render={(props) => <Members {...props} />} />
        <Route exact path="/" render={(props) => <Home {...props} />} />
        <Route exact path="/Proposal" render={(props) => <Proposal {...props} />} />
        <Route exact path="/Projects" render={(props) => <Projects {...props} />} />
      </Switch>
    </div>

  );
}

export default NavigationHandler;
