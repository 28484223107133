
import React from 'react';
import './StyleSheets/App.css';
import Navigation from './Components/Navigation';
import NavigationHandler from './Components/NavigationHandler';
import Footer from './Components/Footer.jsx'

const App = () => {

  return (
    <>
      <Navigation />
      <div id="header_bar"> <p> The Continental Africa Fiscal Transformation Initiative </p> </div>
      <NavigationHandler />
      <Footer />
    </>
  );
}

export default App;
