
import React from 'react';

const Members = (props) => {
  return (
    <>
      <div id="text_container"> <p> There are no members states </p> </div>
    </>
  );
}

export default Members;
