
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaAlignCenter } from 'react-icons/fa';
import logo from '../StyleSheets/logo/logo.png';

const Navigation = () => {
  let screenWidth = window.innerWidth;
  const [menuVisible, changeVisibility] = useState(false);
  const [screenSize, resizeScreen] = useState(screenWidth);
  const dropDownMenu = () => { if (screenSize <= 849) changeVisibility(!menuVisible) }
  const trackViewWidth = () => { resizeScreen(window.innerWidth) }
  useEffect(() => {
    window.addEventListener('resize', trackViewWidth);
  }, []);

  return (
    <div id="nav_bar">
      <div id="responsive_nav">
        <button onClick={dropDownMenu}> <FaAlignCenter /> </button>
        <div id="nav_logo"> <img id="logo_light" src={logo} alt="cafti logo" /> </div>
      </div>
      {
        (menuVisible || screenWidth >= 850) && (
          <ul>
            <li> <Link to="/Requirements" onClick={dropDownMenu}> Requirements </Link> </li>
            <li> <Link to="/Members" onClick={dropDownMenu}> Members </Link> </li>
            <li id="home"> <Link to="/" onClick={dropDownMenu}> Cafti </Link> </li>
            <li> <Link to="/Proposal" onClick={dropDownMenu}> Aims/Goals </Link> </li>
            <li> <Link to="/Projects" onClick={dropDownMenu}> Projects </Link> </li>
          </ul>
        )}


    </div>
  );
}

export default Navigation;
