
import React from 'react';

const Proposal = (props) => {
  return (
    <>
      <div id="text_container">
        <p>
          The Continental Africa Fiscal Transformation Initiative is not a political body, it will only exist in an advisory capacity. Since the- every nation for itself approach has led to stagnant or very slow progress and sometimes even backsliding.
          This organization is meant to establish itself as a common denominator among all the African Nation for the sole purpose of stimulating growth through fiscal transformation. This is likely to be a slow and long process, but we propose to begin with.
        </p>

        <p>
          The Continental Africa Fiscal Transformation Initiative is not a political body, it will only exist in an advisory capacity. Since the- every nation for itself approach has led to stagnant or very slow progress and sometimes even backsliding.
          This organization is meant to establish itself as a common denominator among all the African Nation for the sole purpose of stimulating growth through fiscal transformation. This is likely to be a slow and long process, but we propose to begin with.
        </p>

        <ul>
          <li>
            (I)	Providing an effective and secure streamlined method for National government income deposits including the way taxes are collected.
          </li>

          <li>
            (II)	Conduct research on government income streams and expenditure and promote transparency on how government tax income is spent
          </li>

          <li>
            (III)	Establish protections for some workers in the informal economy and protections for all workers in the formal economy, including a minimum wage
          </li>

          <li>
            (IV)	Establish income tax for all workers in the formal economy and corporate tax for all companies and corporations operating in member states.
          </li>

          <li>
            (V)	Establish a reserve for all the central banks in the member states to monitor and control inflation, oversee the establishment of a secondary base currency to be used in all member states and streamline the funding to used in the construction projects.
          </li>

          <li>
            (VI)	Fund the establishment of regulatory bodies to set standards and promote safe practices where applicable in the industries that need it.
          </li>
        </ul>

      </div>
    </>

  );
}

export default Proposal;
